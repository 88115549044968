#boosted-text {
    color: #8585d0;
    font-size: 38px;
    font-weight: bold;
    vertical-align: top;
    margin-left: 10px;
}

#boosted-image {
    height: 45px;
}