#menu {
    display: inline-block;
    vertical-align: top;
}

    #menu:hover {
        cursor: pointer;
    }

        #menu:hover #menu-list {
            display: block;
            z-index:1000000;
        }

#menu-list {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 300px;
    max-height: 800px;
    overflow-y: scroll;
}

    #menu-list a {
        color: black;
        padding: 12px 16px;
        display: block;
    }

        #menu-list a:hover {
            background-color: #F6FCFF;
        }