#heroSection {
    height: 500px; 
    width: 100%; 
    position:relative; 
    overflow: hidden;
}

#heroSection img {
    opacity: .3;
    position: absolute;
    width:100%;
    margin:0 auto 0 auto;
}

#heroSectionText {
    width: 60%;
    padding: 10%;
    position: relative;
    margin: 0 auto 0 auto;
}

#heroSection p {
    font-size:17px;
}

box {
    display: inline-block;
    margin: 15px;
    width: 330px;
    vertical-align: top;
}

box h3 {
    width: 70%;
}