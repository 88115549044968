.menu-icon {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 6px auto;
}

.menu-icon-container {
    padding-top: 6px;
}

#menu-button {
    background-color: #8585d0;
    color: white;
    height: 50px;
    width: 50px;
}