footer {
    background-color: #263238;
}

#footer-copyright {
    width: 60%;
    padding: 15px 0 15px 15px;
    font-size: small;
    color: #f0f1f5;
}

#footer-tos {
    padding: 15px 0 15px 15px;
}

    #footer-tos a {
        color: #f0f1f5;
        padding-right: 25px;
    }